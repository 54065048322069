<template>
  <div class="notifications-page" v-if="!isLoading">
    <div class="d-flex flex-column justify-content-center text-center notifications-header">
      <img @click="$router.back()" src="../assets/game/left-keyboard-arrow.png" class="go-back-key">
      Notifications
    </div>
    <div class="d-flex flex-column justify-content-center text-center p-4 manage-notifications-text">
      Manage your notifications
    </div>
    <div class="d-flex align-items-center justify-content-center circle-container pt-5 pb-5">
      <div class="d-flex flex-column justify-content-center mt-5 question-mark-circle">
        ?
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center pt-5 ">
      <input class="col-3" type="checkbox" v-model="checked" :checked="isChecked">
      <div class="d-flex flex-column justify-content-center col-7 checkbox-text">Service messages</div>
    </div>
    <div class="d-flex flex-column text-center p-4 service-updates-message">
      I would like to receive updates about our service or any promotions we run
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <button @click="saveButtonClicked" class="mt-4 col-10 save-button">Save</button>
      <button @click="$router.back()" class="mt-1 mb-2 col-5 back-button">Back</button>
    </div>
  </div>
</template>

<script>
import UserDataService from "@/services/userDataService";
import {mapState, mapMutations} from "vuex";

export default {
  name: "ManageNotifications",
  data() {
    return {
      isChecked: false
    }
  },
  async beforeMount() {
    if (this.user.optInServiceMsgs) this.isChecked = true
    this.setIsLoading(false);
  },
  computed: {
    ...mapState(['isLoading', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setUser']),
    async saveButtonClicked() {
      if (this.checked) await UserDataService.updateUserData(this.user.msisdn, this.user.username, 1);
      else await UserDataService.updateUserData(this.user.msisdn, this.user.username, 0);
      const user = await UserDataService.getUserStatus(this.user.token);
      this.setUser(user.data);
      this.$router.push('/settings');
    },
  }
}
</script>

<style scoped>

.notifications-page {
  background-color: #e8e8e8;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.notifications-page::-webkit-scrollbar {
  display: none;
}

.notifications-header {
  position: relative;
  font-weight: 600;
  font-size: 18px;
  height: 45px;
  background-color: #EF151C;
  color: #FFFFFF;
}

.go-back-key {
  position: absolute;
  height: 20px;
  left: 27px;
  top: 13px;
}

.manage-notifications-text {
  height: 70px;
  border-radius: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: #2D343B;
  color: #FFFFFF;
}

.question-mark-circle {
  position: absolute;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 80px;
  padding-bottom: 10px;
  padding-left: 40px;
  background-color: #EF151C;
}

.circle-container {
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
  color: #FFFFFF;
}

input {
  height: 35px;
}

.checkbox-text {
  font-weight: 700;
}

.service-updates-message {
  font-weight: 700;
}

.save-button {
  height: 45px;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  background-color: #EF151C;
  color: #FFFFFF;
}

.back-button {
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  border: 0;
  background-color: #2D343B;
  color: #FFFFFF;
}

</style>
